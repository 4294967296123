
import Vue from "vue";
import { claimsMapState } from "@/store/modules/claims";
import Help from "@/components/Help/Inquiries.vue";
import * as selectOptions from "@/helpers/selectOptions";
import LossForm from "@/components/lossForm/LossForm.vue";

interface Idata {
  didInit: boolean;
  activeIndex: string;
  error: string;
  message: string;
  modalMessage: string;
  lastClaimId: string;
  customClaimUrlString: string;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
  policyData: any;
  formIsNotValid: boolean;
  messages: any[];
}

export default Vue.extend({
  name: "FileClaim",
  components: {
    LossForm,
    Help
  },
  data(): Idata {
    return {
      policyData: [],
      didInit: false,
      activeIndex: "info",
      modalMessage: "",
      customClaimUrlString: "",
      lastClaimId: "",
      error: "",
      message: "",
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The policy change request you are looking for cannot be found.",
      formIsNotValid: true,
      messages: [
        {
          title: "Claims Inquiries",
          contacts: selectOptions.claimsInquiries
        },
        {
          title: "Claims Contacts",
          contacts: selectOptions.claimsContacts
        }
      ]
    };
  },
  methods: {
    goToLossForm(policyData: any) {
      const customData = this.customClaimUrlString
        ? this.customClaimUrlString
        : policyData;
      this.$router.push(`/lossformadmin/new/${customData}`);
      this.$modal.hide("lossFormModal");
    },
    goToExistingClaim() {
      this.$router.push(`/lossformadmin/view/${this.lastClaimId}`);
      this.$modal.hide("lossFormModal");
    },
    toolbarSelectItem(event: string) {
      switch (event) {
        case "create-loss-form":
          (this.$refs.lossFormComponent as any).createLossForm();
          break;
        default:
          break;
      }
    },
    handleFormField(value: any) {
      this.formIsNotValid = value;
    },
    showExceededClaimsModal(data: {
      message: string;
      policyData: string;
      lastClaimId: string;
    }) {
      this.modalMessage = data.message;
      this.lastClaimId = data.lastClaimId;
      this.customClaimUrlString = data.policyData;
      this.$modal.show("exceededClaims");
    }
  },
  computed: {
    ...claimsMapState(["makingApiRequest"])
  }
});
