
import { Session } from "@/helpers/processSession";
import { authMapGetters, authMapState } from "@/store/modules/auth";
import {
  claimsMapActions,
  claimsMapGetters,
  claimsMapMutations,
  claimsMapState
} from "@/store/modules/claims";
import { padPolicyNumber } from "@/helpers/generalHelpers";
import { getUserCompanies, getCompanyPrefix } from "@/helpers/companies";
import { isAfter, format } from "date-fns";
import { get, omit } from "lodash";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

import Vue from "vue";
export default Vue.extend({
  name: "lossformfields",
  props: {
    titleOfSubmitButton: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    formType: {
      type: String,
      default: ""
    }
  },
  components: {
    CustomAlert
  },
  data() {
    return {
      lossForm: {
        policyNumber: "",
        effectiveDate: "",
        companyPrefix: "",
        companyNumber: "",
        category: "claims"
      },
      userCompanies: getUserCompanies("lossForm"),
      companyPrefixOption: [],
      isCompanyChecked: false,
      isCompanyPrefixChecked: false,
      errorMessage: "",
      successMessage: "",
      session: new Session(),
      loadingText: ""
    };
  },
  created() {
    if (!get(this.lossForm, "effectiveDate")) {
      this.lossForm.effectiveDate = new Date() as any;
    }
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    ...authMapState(["initData"]),
    ...claimsMapState(["makingApiRequest"]),
    formIsValid(): boolean {
      return (
        !this.lossForm.companyPrefix ||
        !this.lossForm.companyNumber ||
        !this.lossForm.effectiveDate ||
        !this.lossForm.policyNumber
      );
    },
    isPolicyInquiry(): boolean {
      return this.formType === "policyInquiry";
    },
    pickerOptions(): any {
      return {
        disabledDate: this.isPolicyInquiry
          ? null
          : (d: Date) => isAfter(d, new Date())
      };
    }
  },
  methods: {
    ...claimsMapActions(["getLossData"]),
    ...claimsMapMutations(["SET_EDIT", "SET_STATES"]),
    ...claimsMapActions(["getFiservData"]),
    ...claimsMapGetters(["getLossFormData"]),
    handleChangeCompanyNumber(event: any) {
      this.lossForm.companyNumber = event;
      this.$emit("FormField", { key: "companyNumber", value: event });
      this.$emit("isFormNotValid", this.formIsValid);
    },
    handleCompanyPrefix(event: any) {
      this.lossForm.companyPrefix = event;
      this.$emit("FormField", { key: "companyPrefix", value: event });
      this.$emit("isFormNotValid", this.formIsValid);
    },
    handlePolicyNumber(event: any) {
      this.lossForm.policyNumber = event;

      this.$emit("FormField", { key: "policyNumber", value: event });
      this.$emit("isFormNotValid", this.formIsValid);
    },
    doPadPolicyNumber(policyNumber: string): void {
      const processedPolicyNumber = padPolicyNumber(policyNumber);
      this.lossForm.policyNumber = processedPolicyNumber;
    },
    handleEffectiveDate(event: any) {
      this.lossForm.effectiveDate = event;
      this.$emit("FormField", { key: "effectiveDate", value: event });
      this.$emit("isFormNotValid", this.formIsValid);
    },
    async createLossForm(): Promise<void> {
      this.errorMessage = "";
      try {
        if (this.formIsValid) {
          this.$appNotify({
            message: "Please fill all required form fields. ",
            type: "error",
            title: "Validation error"
          });
          return;
        }
        this.loadingText = "Creating loss form. Please wait...";
        const requestData = {
          ...omit(this.lossForm, ["category"])
        };
        if (this.formType && this.isPolicyInquiry) {
          this.$emit("success", requestData);
          return;
        }
        await this.getLossData(requestData);
        const receivedClaimsData = this.getLossFormData();
        if (!receivedClaimsData) {
          this.errorMessage = "Error fetching loss data. Try again later";
        } else if (
          (receivedClaimsData.policyStatus === "exceeded" &&
            this.$isCurrentUserAdmin) ||
          receivedClaimsData.policyStatus === "exist"
        ) {
          this.$emit("exceeded", {
            message: receivedClaimsData.policyStatusMessage,
            lastClaimId: receivedClaimsData.lastClaimId,
            policyData: `${this.lossForm.companyPrefix}+${
              this.lossForm.policyNumber
            }+${this.lossForm.companyNumber}+${this.formatDate(
              this.lossForm.effectiveDate
            )}`
          });
        } else if (
          receivedClaimsData.policyStatus === "exceeded" &&
          !this.$isCurrentUserAdmin
        ) {
          this.errorMessage =
            receivedClaimsData.policyStatusMessage ||
            "Two or more claims been filed for this policy in the past 14 days. Please call the claims department before continuing. 866-522-0361/972-595-5701.";
        } else {
          this.$emit(
            "success",
            `${this.lossForm.companyPrefix}+${this.lossForm.policyNumber}+${
              this.lossForm.companyNumber
            }+${this.formatDate(this.lossForm.effectiveDate)}`
          );
        }
      } catch (err) {
        const error = err.message || err.raw.response.data.response;
        if (Array.isArray(error)) {
          this.errorMessage = error.join("\n");
        } else {
          this.errorMessage = error;
        }
        this.$bugSnagClient.notify(err);
      }
    },
    formatDate(date: string): string {
      return format(new Date(date), "yyyy-MM-dd");
    },
    resetFields() {
      this.lossForm = {
        policyNumber: "",
        effectiveDate: "",
        companyPrefix: "",
        companyNumber: "",
        category: "claims"
      };
    }
  },
  watch: {
    "lossForm.effectiveDate": {
      handler(newValue: any) {
        this.$emit("FormField", { key: "effectiveDate", value: newValue });
      }
    },
    "lossForm.companyNumber": {
      handler(newValue: any) {
        (this.companyPrefixOption as any) = getCompanyPrefix(
          this.userCompanies,
          newValue
        );
        this.lossForm.companyPrefix = "";
      }
    }
  }
});
